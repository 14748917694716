import { Layout } from "antd";
import "./style.css";
import { UIImage } from "../image";
import { UIDropdown } from "../dropdown";
import { dropDownIcon, hammerBergIcon, logoutLightIcon } from "../../../assets/icons";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Page } from "../../../routes/config";
import { UIAvatar } from "../avatar";
import { scRoundLogo, headerTopLogo } from "../../../assets/images";
import { authStateSelector, logOut, selectCurrentUser } from "../../../redux/features/auth/authSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { UIbutton } from "../button";
import { StopEventAlert } from "./warningStop";
import { socket } from "../../../redux/services/webSocket";
import { selectCurrentAppState, setSessionExist } from "../../../redux/features/app/appSlice";

const { Header } = Layout;

const SmartCosmosLogo = ({ collapsed }: any) => (
  <Link to={Page.ACTIVE_PROCESS}>
    <div className="sclogo">
      <div className="sphere"><UIImage src={scRoundLogo} text="small-logo" width={45} /></div>
      {!collapsed &&
        <div className="logotext">
          <UIImage src={headerTopLogo} text="text-logo" />
        </div>}
    </div>
  </Link>
)

const UIHeader = (props: any) => {
  const user = useSelector(selectCurrentUser)
  const {isLoggedIn} = useSelector(authStateSelector)
  const { collapsed, setCollapsed } = props;
  const showSidebar = () => {
    if (window.location.pathname === Page.ACTIVITY_LOGS) {
      return false
    } else {
      return true
    }
  }
  const dispatch = useDispatch()
  const {sessionExist} = useSelector(selectCurrentAppState)
  const {state} = useLocation()
  const userDropDownOptions = [
    {
      label: "Logout",
      key: "1",
      onClick: () => {
        window.localStorage.clear();
        dispatch(logOut({}))
      },
      icon: <img src={logoutLightIcon} alt="icon" />,
    },
  ];

  const userLogout = ()=>{
    window.localStorage.clear();
    dispatch(logOut({}));
    dispatch(setSessionExist(false))
  }

  const [stopAlert, setStopAlert] = useState()
  const [openAlert, setOpenAlert] = useState(false);

  socket.on("message", (data:any) => {
    setOpenAlert(true)
    setStopAlert(data)
    console.log(data,"message for alert event")
  })

  return (
    <>
    <Header className="site-layout-background fixedHeader header">
      <div
        className={`logo ${collapsed ? "collapsedWidth" : "sidebarWidth"}`}
        style={{ textAlign: "center", transition: '0s' }}
      >
        <SmartCosmosLogo collapsed={collapsed} />

      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "calc(100% - 250px)",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {showSidebar() ? <div
            className="hammerberg"
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          >
            <UIImage src={hammerBergIcon} text="icon" />
          </div> : null}
        </div>
        <UIDropdown items={userDropDownOptions} placement="bottom">
          <div className="profileImageSection">
            <div className="profileImage">
              {
                user?.imageUrl ? <UIImage src={user?.imageUrl} />
                  :
                  <UIAvatar text={user?.name} type="username" />
              }

            </div>
            <div className="info">
              <div className="name">{user?.name}</div>
              <div className="role">{user?.roleName}</div>
            </div>
            <div className="icon">
              <img src={dropDownIcon} className="header-logout" alt="dropdown icon" />
            </div>
          </div>
        </UIDropdown>
      </div>
    </Header>
          {/* session expire alert */ }
  {
    !sessionExist &&
      (<div className="session_expired_alert">
        <div className="content">
          <div className="message">Session Expired! Need to Login</div>
          <div className="btn"><UIbutton type="info" size="md" onPress={userLogout}>Login</UIbutton></div>
        </div>
      </div>)
  }  
  <StopEventAlert processData={state} alertDetails={stopAlert} openAlert={openAlert} setOpenAlert={setOpenAlert}/>
  </>
  );
};

export default UIHeader;
