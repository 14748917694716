/* eslint react-hooks/exhaustive-deps: 0 */

import { Card, message, TablePaginationConfig } from "antd";
import { useEffect, useState } from "react";
import { deleteDarkIcon } from "../../../assets/icons";
import { ModalButton, ModalPrimaryText, ModalType } from "../../../config/enum";
import { UIConfirmModal } from "../../common/confirmModal";
import { UIDropdown } from "../../common/dropdown";
import * as util from "util";
import { UITable } from "../../common/table"
import './style.css'
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import { page, rolesOrder } from "../../../config/constants";
import { setStopButton, setSyncedData, setTableData } from "../../../redux/features/app/appSlice";
import { useGetAllActiveProcessQuery, useStopActiveProcessMutation } from "../../../redux/services/processSlice";
import { useDispatch, useSelector } from "react-redux";
import { authStateSelector } from "../../../redux/features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { Page } from "../../../routes/config";
import UITooltip from "../../common/tooltip";
import { EllipsisOutlined } from "@ant-design/icons";
import moment from "moment";
import { getFilterInfo, setFilterState } from "../../../redux/features/filter/filterSlice";

export const ActiveProcess = (props:any) => {
  const [tableData, setTableActiveData] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const filterState = useSelector(getFilterInfo);
  const currentURL = window.location.href;
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: filterState?.page &&  currentURL == filterState?.url ?  filterState?.page : page?.current,
    pageSize: filterState.limit && currentURL == filterState?.url ? filterState.limit : page?.pageSize,
    showSizeChanger: true,
  });
  const {tenantId, user} = useSelector(authStateSelector)
  const [sort, setSort] = useState<any>({
    ...((currentURL == filterState?.url && filterState?.sortBy) && {sortBy: filterState?.sortBy}),
    ...((currentURL == filterState?.url && filterState?.sortOrder) && {sortOrder: filterState?.sortOrder}),
  });
  let queryParams = {
    page:  pagination.current,
    limit: pagination.pageSize,
    type: "ActiveProcess",
    tenantId: tenantId,
    ...sort
  }

  useEffect(()=>{
    dispatch(setFilterState({
      page: queryParams?.page,
      limit : queryParams?.limit,
      sortBy: queryParams?.sortBy,
      sortOrder: queryParams?.sortOrder
    }));
},[
  queryParams?.page,
  queryParams?.limit,
  queryParams?.sortBy,
  queryParams?.sortOrder
]);

  const getActiveProcess = useGetAllActiveProcessQuery({queryParams, roleId: user?.roleId}, {refetchOnMountOrArgChange:true})

  useEffect(()=>{
    if(getActiveProcess?.isSuccess){
      setTableActiveData(getActiveProcess?.data?.result)
      setTotal(getActiveProcess?.data?.result?.length)
      setLoading(false)
    }
  },[getActiveProcess?.status === "fulfilled"])

 
  const navigate = useNavigate()
  const [stopProcess, stopProcessInfo] = useStopActiveProcessMutation()
  const dispatch = useDispatch()
  useEffect(()=>{
    if(stopProcessInfo?.isSuccess){
      getActiveProcess?.refetch()
      dispatch(setTableData([]))
      dispatch(setSyncedData({}))
      dispatch(setStopButton("start"))
      message.success({content:"Process Stopped Successfully", key:"notificationKey", duration:2, className:"successMessage"})
    }
  },[stopProcessInfo?.isSuccess])
  

  useEffect(()=>{
    if(stopProcessInfo?.isError){
      message.error({content:stopProcessInfo?.error?.data?.error?.message, key:"notificationKey", duration:2, className:"errorMessage"})
    }
  },[stopProcessInfo?.isError])

  const onRowClick = (row: any) => {
     if(user?.username === row?.userName){
      navigate(Page.ACTIVITY_LOGS, {state:{data:row,stopButton:true,startLoop: row?.processData?.isCustomizedLoop ?  row?.processData?.startLoop : 0, isActiveProcess : true}})
     }else{
      message.info({content:"You can not view the process started by other users!", key:"notificationKey", duration:2, className:"infoMessage"})
     }
    dispatch(setStopButton(""))
    setSelectedRow(row);
  };

  const dropdown = (row:any) => {
    if(row?.roleName === user?.roleName){
      if(row?.userName === user?.username){
        return (
          <UIDropdown
        data-testid="delete"
        items={[
          {
            label: "Stop and Exit",
            key: "1",
            icon: <img src={deleteDarkIcon} alt="delete" />,
            onClick: () => onDeleteClick(row),
          }
        ]}
        placement="bottom"
      >
          < EllipsisOutlined className="ellipses"/>
      </UIDropdown>
        )
      }else {
        return null
      }
    }else{
      if(rolesOrder?.[row?.roleName] > rolesOrder?.[user?.roleName]){
        return (
          <UIDropdown
        data-testid="delete"
        items={[
          {
            label: "Stop and Exit",
            key: "1",
            icon: <img src={deleteDarkIcon} alt="delete" />,
            onClick: () => onDeleteClick(row),
          }
        ]}
        placement="bottom"
      >
          < EllipsisOutlined className="ellipses"/>
      </UIDropdown>
      )}else {
        return null
      }
    }
  }

  const onDeleteClick = (row: any) => {
    setDeleteModal(true);
    setSelectedRow(row);
  };

  const handleDelete = () => {
    stopProcess({userId: selectedRow?.userId, stopBy: user?.userId, processName:selectedRow?.processName ,activity_logs: false, processId:selectedRow?.processId,  type:"exit", deviceId:selectedRow?.deviceId})
    setDeleteModal(false);
  };
  const onDeleteModalClose = () => {
    setDeleteModal(false);
  };

  useEffect(()=>{
    getActiveProcess?.refetch()
  },[])

  const columns = [
    {
      title: "Tenant",
      dataIndex: "tenantName",
      className:"activeProcessTable",
      key:"tenantName",
      sorter: true,
      ellipsis: true,
      width: "30%",
      render: (field: any, row: any) => {
        return (
          <div
            className="d-flex align-items-center"
            onClick={() => onRowClick(row)}
          >
            <UITooltip title={row?.tenantName} placement="top">
            <div className="pl-10 tableData" key="tenantName">{field}</div>
            </UITooltip>
          </div>
        );
      },
    },
    {
      title: "Process Name",
      dataIndex: "processName",
      // sorter: true,
      key:"processName",
      className:"activeProcessTable",
      ellipsis: true,
      width: "30%",
      render: (field: any, row: any) => {
        return (
          <div
            className="d-flex align-items-center"
            onClick={() => onRowClick(row)}
          >
            <UITooltip title={row?.processName} placement="top">
            <div className="pl-10 tableData slice" key="processName">{field}</div>
            </UITooltip>
          </div>
        );
      },
    },
    {
      title: "Device Name",
      dataIndex: "deviceName",
      className:"activeProcessTable",
      key:"deviceName",
      width: "30%",
      ellipsis: true,
      // sorter: true,
      render: (field: any, row: any) => {
        return (
          <div  onClick={() => onRowClick(row)}>
            <UITooltip title={row?.deviceName} placement="top">
            <div className="tableData slice" key="deviceName">{field}</div>
            </UITooltip>
          </div>
        );
      },
    },
    {
      title: "Device Status",
      dataIndex: "deviceStatus",
      width: "30%",
      key:"deviceStatus",
      className:"activeProcessTable",
      ellipsis: true,
      // sorter: true,
      render: (field: any, row: any) => {
        return (
          <div onClick={() => onRowClick(row)}>
            <UITooltip title={row?.deviceStatus} placement="top">
            <div className="tableData slice" key="deviceStatus">{field}</div>
            </UITooltip>
          </div>
        );
      },
    },
    {
      title: "IP Address",
      dataIndex: "ipAddress",
      width: "30%",
      className:"activeProcessTable",
      key:"ipAddress",
      ellipsis: true,
      // sorter: true,
      render: (field: any, row: any) => {
        return (
          <div onClick={() => onRowClick(row)}>
             <UITooltip title={row?.ipAddress} placement="top">
            <div className="tableData slice" key="ipAddress">{field}</div>
            </UITooltip>
          </div>
        );
      },
    },
    {
      title: "Site",
      key:"siteName",
      dataIndex: "siteName",
      width: "22%",
      className:"activeProcessTable",
      ellipsis: true,
      render: (field: any, row: any) => {
        return (
          <div onClick={() => onRowClick(row)}>
            <UITooltip title={row?.siteName} placement="top">
            <div className="tableData slice" key="siteName">{field}</div>
            </UITooltip>
          </div>
        );
      },
    },
    {
      title: "Zone",
      dataIndex: "zoneName",
      key:"zoneName",
      width: "22%",
      className:"activeProcessTable",
      ellipsis: true,
      render: (field: any, row: any) => {
        return (
          <div onClick={() => onRowClick(row)}>
            <UITooltip title={row?.zoneName} placement="top">
            <div className="tableData slice" key="zoneName">{field}</div>
            </UITooltip>
          </div>
        );
      },
    },
    {
      title: "Last Sync Time",
      dataIndex: "lastSyncTime",
      width: "30%",
      key:"lastSyncTime",
      className:"activeProcessTable",
      ellipsis: true,
      render: (field: any, row: any) => {
        return (
          <div onClick={() => onRowClick(row)}>
            <UITooltip title={moment(field).format("DD MMM YYYY hh:mm A")} placement="top">
              <div className="tableData slice" key="lastSyncTime">{moment(field).format("DD MMM YYYY hh:mm A")}</div>
            </UITooltip>
          </div>
        );
      }
    },
    {
      title: "Process status",
      dataIndex: "isReadyForProcessing",
      width: "30%",
      key:"isReadyForProcessing",
      className:"activeProcessTable",
      ellipsis: true,
      render: (field: any, row: any) => {
        return (
          <div onClick={() => onRowClick(row)}>
            <UITooltip title={row?.isReadyForProcessing} placement="top">
              <div className="tableData slice" key="isReadyForProcessing">{field}</div>
              </UITooltip>
          </div>
        );
      },
    },
    {
      title: "USERNAME",
      dataIndex: "userName",
      width: "25%",
      key:"userName",
      className:"activeProcessTable",
      ellipsis: true,
      render: (field: any, row: any) => {
        return (
          <div onClick={() => onRowClick(row)}>
            <UITooltip title={row?.userName} placement="top">
            <div  className="slice" key="userName">{field}</div>
            </UITooltip>
          </div>
        );
      },
    },
    
    {
      dataIndex: "login",
      width: "15%",
      key:"login",
      className:"activeProcessTable",
      fixed:"right",   
      render: (login: any, row: any) => {
        return (
          dropdown(row)
      )},
    },
  ];

  interface DataType {
    name: {
      first: string;
      last: string;
    };
    description: string;
    lastUpdated: string;
    createdAt: string;
  }
  interface Params {
    pagination?: TablePaginationConfig;
    sorter?: SorterResult<any> | SorterResult<any>[];
    total?: number;
    sortField?: string;
    sortOrder?: string;
  }

  useEffect(() => {
    setPagination({
      ...pagination,
      locale: { items_per_page: "" },
      total,
    });
  }, [total]);

  const fetchData = (params: Params = {}) => {
    setLoading(true);
    getActiveProcess?.refetch()
    setSort({
      sortBy: params.sortField,
      sortOrder:
        params.sortOrder === "ascend"
          ? "1"
          : params.sortOrder === "descend"
          ? "-1"
          : undefined,
    });
    setPagination({
      ...params.pagination,
      locale: { items_per_page: "" },
      total,
    });
  };

  const handleTableChange = (
    newPagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<DataType>
  ) => {
    fetchData({
      sortField: sorter.field as string,
      sortOrder: sorter.order as string,
      pagination: newPagination,
      ...filters,
    });
  };

    return (
      <>
        <section className="listing-section">
        <Card className="uicard table uiinner-card">
          <div className="card-body">
            <UITable
              columns={columns}
              data={tableData}
              rowKey="tenant"
              pagination={pagination}
              loading={loading}
              handleTableChange={handleTableChange}
              scroll={{ x: 1000, y: 500 }}
              rowSelection={onRowClick}
            />
          </div>
        </Card>
      </section>
      <UIConfirmModal
        data-testid="modal-id"
        key="deleteTenant"
        visible={deleteModal}
        cancelCallback={onDeleteModalClose}
        confirmCallback={handleDelete}
        cancelButton={ModalButton.CANCEL}
        confirmButton={ModalButton.CONFIRM}
        primaryText={util.format(ModalPrimaryText.DELETE, selectedRow.processName)}
        type={ModalType.WARN}
      />
      </>
    )
}