/* eslint react-hooks/exhaustive-deps: 0 */

import moment from "moment";
import { Col, message, Row } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { officeDevice, syncClock, offlineDevice } from "../../../assets/images";
import { selectCurrentAppState, setCurrentStep, setStopButton, setSyncedData, setTableData } from "../../../redux/features/app/appSlice";
import { authStateSelector } from "../../../redux/features/auth/authSlice";
import { usePauseProcessMutation, useResumeProcessMutation, useStartProcessMutation, useStopActiveProcessMutation } from "../../../redux/services/processSlice";
import { UIbutton, UIsecondaryButton } from "../../common/button";
import { ModalButton, ModalPrimaryText, ModalType, WebsocketKey } from "../../../config/enum";
import { UIConfirmModal } from "../../common/confirmModal";
import { useGetDeviceStatusByMacQuery } from "../../../redux/services/deviceSite";
import { compareIcon, dataSyncIcon, gpio, gpioSound, notifyIcon, printLabel, scanTag, waitIcon } from "../../../assets/icons";
import { UIImage } from "../../common/image";
import { ProcessName } from "../../../config/constants";
import { socket } from "../../../redux/services/webSocket";

export const DeviceDetail = (props: any) => {
  const { data } = props
  let beforeStep = data?.data?.steps ? data?.data?.steps : data?.data?.processData[0]?.steps
  const processName = data?.data?.processName;
  const processId = data?.data?.processId
  const deviceId = data?.data?.deviceId
  let steps = beforeStep ? (typeof (beforeStep[0]) === "string") ? JSON.parse(beforeStep) : (beforeStep) : []

  const startLoop = parseInt(data?.data?.startLoop) || 0
  const { stopbutton } = useSelector(selectCurrentAppState)
  let navigate = useNavigate();
  const { syncedData, currentStep } = useSelector((state: any) => state.app)
  const { user } = useSelector(authStateSelector)
  const loopActionPosition = startLoop ? startLoop : 0;
  const [stepIndex, setStepIndex] = useState(0);
  const distance = startLoop
  const [startProcess, setStartProcess] = useStartProcessMutation()
  const [stopProcess, setStopProcess] = useStopActiveProcessMutation()
  const [pauseProcess, pauseProcessInfo] = usePauseProcessMutation()
  const [resumeProcess, resumeProcessInfo] = useResumeProcessMutation()
  const [exitModal, setExitModal] = useState(false)
  let x: any;
  
  console.log("PROCESS NAMWE", processName)
  if(processName != ProcessName.CHECK_STOCK){
    x= setInterval(() => {
      if (stepIndex < steps?.length - 1) {
        // setStepIndex(stepIndex + 1);
      dispatch(setCurrentStep(stepIndex + 1))

      } else {
        // setStepIndex(loopActionPosition);
      dispatch(setCurrentStep(loopActionPosition))

      }
      clearInterval(x);
    }, 1500);
  } else{
    socket.on(WebsocketKey.CHECK_REF_STOCK,(data)=>{
      console.log("DATA Recieved for state change", data)
      if((processId == data?.data?.processId) && (deviceId == data?.data?.deviceId)){
      let stateSeq = steps?.findIndex((step:any)=>step?.name == data?.state)
        dispatch(setCurrentStep(stateSeq))
      }
    })
  }

  useEffect(()=>{
    setStepIndex(currentStep)
  },[currentStep])


  useEffect(()=>{
    return(()=>{
      dispatch(setCurrentStep(0))
    })
  },[])

  const stepProcess: any = {
    'Scan Tag': scanTag,
    'GPIO Light': gpio,
    'GPIO Sound': gpioSound,
    'Print Label': printLabel,
    'Scan Multiple': compareIcon,
    'Data Sync': dataSyncIcon,
    'Notify': notifyIcon,
    'Wait': waitIcon
  };
  console.log("data process ", data, startLoop)
  
  const getDeviceStatusByMac = useGetDeviceStatusByMacQuery({ macDevice: data?.data?.mac || data?.data?.macAddress }, { refetchOnMountOrArgChange: true, pollingInterval: 3000 })

  const onStartClick = () => {
    startProcess({ userId: user?.userId, processId: data?.data?.processId, deviceId: data?.data?.deviceId })
  }

  const dispatch = useDispatch()

  // activity logs button
  useEffect(() => {
    if (setStartProcess?.isError) {
      message.error({
        content: setStartProcess?.error?.data?.error?.message,
        key: "notificationKey",
        duration: 2,
        className: "errorMessage"
      })
    }
  }, [setStartProcess?.isError])
  console.log(setStartProcess?.isError, "processid");

  const onStopClick = () => {
    stopProcess({
      stopBy: user?.userId,
      processId: data?.data?.processId,
      processName: data?.data?.processName,
      deviceId: data?.data?.deviceId,
      type: "exit",
      activity_logs: true,
      userId: user?.userId
    })
  };

  const onPauseClick = () => {
    pauseProcess({ userId: user?.userId, processId: data?.data?.processId, deviceId: data?.data?.deviceId, type: "pause" })
  };

  const onResumeClick = () => {
    resumeProcess({ userId: user?.userId, processId: data?.data?.processId, deviceId: data?.data?.deviceId, type: "resume", workflowName: data?.data?.workflowName })
  }


  useEffect(() => {
    if (setStartProcess?.isSuccess) {
      dispatch(setStopButton("pause"))
      message.success({ content: "Process Started Successfully", key: "notificationKey", className: "successMessage", duration: 2 })
    } else if (resumeProcessInfo?.isSuccess) {
      dispatch(setStopButton("pause"))
      message.success({ content: "Process Resumed Successfully", key: "notificationKey", className: "successMessage", duration: 2 })
    }
  }, [setStartProcess?.isSuccess, resumeProcessInfo?.isSuccess])

  useEffect(() => {
    if (pauseProcessInfo?.isSuccess) {
      dispatch(setStopButton("resume"))
      message.success({ content: "Process Paused Successfully", key: "notificationKey", className: "successMessage", duration: 2 })
    }
  }, [pauseProcessInfo?.isSuccess])

  useEffect(() => {
    if (setStopProcess?.isSuccess) {
      dispatch(setStopButton("start"))
      message.success({ content: "Process Stopped Successfully", key: "notificationKey", duration: 2, className: "successMessage" })
      navigate(`../executeprocess`)
      dispatch(setTableData([]))
      dispatch(setSyncedData({}))
    }
  }, [setStopProcess?.isSuccess])


  const processNormalButton = () => {
    return (
      <>
        <Col md={12} sm={12} lg={12}>
          <UIsecondaryButton onPress={() => exitButton()}>EXIT</UIsecondaryButton>
        </Col>
        <Col md={12} sm={12} lg={12}>
          {(data?.data?.isReadyForProcessing === "pause" || stopbutton === "resume") ?
            <UIbutton type="info" size="sm" onPress={onResumeClick}>
              RESUME
            </UIbutton> : 
            ((data?.data?.isReadyForProcessing === "start") || (data?.data?.isReadyForProcessing === "resume") || (stopbutton === "pause")) ?
              <UIbutton type="info" size="sm" onPress={onPauseClick}>
                PAUSE
              </UIbutton> :
              <UIbutton type="info" size="sm" onPress={onStartClick}>
                START
              </UIbutton>}
        </Col>
      </>)
  }

  const processButton = () => {
    if (data?.data?.roleName === user?.roleName && data?.data?.userName === user?.username) {
      return (
        <>
          <Col md={12} sm={12} lg={12}>
            <UIsecondaryButton onPress={() => exitButton()}>EXIT</UIsecondaryButton>
          </Col>
          <Col md={12} sm={12} lg={12}>


          {stopbutton !== "" ? (<> 
          
          {stopbutton == 'pause' && (<UIbutton type="info" size="sm" onPress={onPauseClick}>PAUSE</UIbutton>)}
          {stopbutton == 'resume' && (<UIbutton type="info" size="sm" onPress={onResumeClick}>RESUME</UIbutton>)}
         
</> ) : data?.data?.isReadyForProcessing ? (<>

{ data?.data?.isReadyForProcessing == 'start' || data?.data?.isReadyForProcessing == 'resume' ?   <UIbutton type="info" size="sm" onPress={onPauseClick}>PAUSE</UIbutton>
  : data?.data?.isReadyForProcessing == 'pause' ? <UIbutton type="info" size="sm" onPress={onResumeClick}>RESUME</UIbutton> 
  : <UIbutton type="info" size="sm" onPress={onStartClick}>START</UIbutton> }

</>) : "" }
          </Col>
        </>)
    } else {
      return null
    }
  }

  const getProcessStatus = () => {
    if (setStartProcess?.isSuccess || data?.data?.isReadyForProcessing === "pause" || stopbutton === "pause" || stopbutton === "resume" || data?.data?.isReadyForProcessing === "resume" || data?.data?.isReadyForProcessing === "start") {
      return true
    } else {
      return false
    }
  }
  console.log(setStartProcess?.isSuccess || data?.data?.isReadyForProcessing === "pause" || stopbutton === "pause" || stopbutton === "resume" || data?.data?.isReadyForProcessing === "resume" || data?.data?.isReadyForProcessing === "start", "processdata");


  //preview function
  const imageForProcessAction = () => {
    return steps?.map((step: any, index: any, id: any) => {
      return (
        <>
        {processName == ProcessName.CHECK_STOCK ? 
        <Col key={id} span={6}>
        <div className={`stepBox ${index === stepIndex && "active"}`}>
          <div className={`icon ${getProcessStatus() &&(index === stepIndex) ? "" : ""}`}>
            <img className={getProcessStatus() &&(index === stepIndex) ? "scanTag colorIcon pulse" : "scanTag"} src={stepProcess[step?.name]} alt="uhf" />
          </div>
        </div>
      </Col>
      :
          <Col key={id}>
            <div className={`stepBox ${index === stepIndex && "active"}`}>
              <div className={`icon ${getProcessStatus() ? "pulse" : ""}`}>
                <img className={getProcessStatus() ? "scanTag colorIcon" : "scanTag"} src={stepProcess[step?.name]} alt="uhf" />
              </div>
            </div>
          </Col>
    }
        </>
        )
    })
  }

  const exitButton = () => {
    setExitModal(true)
  }

  const onExitModalClose = () => {
    setExitModal(false)
  }

  var CurrentDate = moment().format("hh:mm:ss A");

  console.log(getDeviceStatusByMac?.data?.result[0]?.status, "devicestatus");
  
  const getStepLoopAlignment = () =>{
    let alignNumber = (window.innerWidth * 4.263)/100
    let width = (window.innerWidth * 8.68)/100
    let arrow = (window.innerWidth * 4.81)/100
    console.log(">>>>>>>>>>>> window width", window.innerWidth, {
      alignNumber,
      width,
      arrow
    })

    return ({
      alignNumber,
      width,
      arrow
    })
  }

  const getStepArrowMarker = () => {
    return(
      processName == ProcessName.CHECK_STOCK ? 
      <>
      <span className="loopborder1" style={{ left: getStepLoopAlignment().alignNumber, width: steps?.length === 1 ? `calc(100% - 45px)` : `calc(100% - ${getStepLoopAlignment().width}px)` }}></span>
      {steps?.length === 1 ?
        <span className="loopborder" style={{ right: 0, width: `calc(100% - ${(distance + 1) * 45}px)` }}></span>
        :
        <span className="loopborder" style={{ right:  getStepLoopAlignment().alignNumber, width:  `calc(100% - ${getStepLoopAlignment().width}px)` }}></span>
      }
      <span className="looparrow" style={{ right: `calc(100% -  ${getStepLoopAlignment().arrow}px)` }}></span>
      </>
      :
      <>
      <span className="loopborder1" style={{ left: 45, width: steps?.length === 1 ? `calc(100% - 45px)` : `calc(100% - 90px)` }}></span>
                  {steps?.length === 1 ?
                    <span className="loopborder" style={{ right: 0, width: `calc(100% - ${(distance + 1) * 45}px)` }}></span>
                    :
                    <span className="loopborder" style={{ right: 45, width: `calc(100% - 2*${(distance + 1) * 45}px)` }}></span>
                  }
                  <span className="looparrow" style={{ right: `calc(100% - 2*${(distance + 1) * 45}px + 39px)` }}></span>
      </>
    )
  }

  const getUpperRowStepper = () => {
    return (
      processName == ProcessName.CHECK_STOCK ? 
      <Col  span={24}>
            <Row>
                {steps?.map((step: any, id: any) => {
                return <Col span={6} className="upperRow"><span className="spanTag slice" key={id}>{step?.name}</span></Col>
              })}
          </Row>
      </Col>
      :
      <Col className="upperRow" span={24}>{steps?.map((step: any, id: any) => {
        return <span className="spanTag slice" key={id}>{step?.name}</span>
      })}</Col>
    )
    
  }

  const getClassNameForSteps = ()=> {
    let className = ""
    processName == ProcessName.CHECK_STOCK ? 
                  className = "justify-content-center processStepsRow processCR"
                  :
                  className = "justify-content-center processStepsRow"
    return className
  }
  

  return (
    <>
      <Row>
        <Col md={13} lg={15} xl={15}>
          <Row>
            <Col span={24} className="activeContent">
              <b>Process Name:</b> {data?.data?.processName ? data?.data?.processName : data?.data?.name}
            </Col>
            <Col span={24} className="activeContent margin">
              <b>Site:</b> {data?.data?.siteName}
            </Col>
            <Col span={24} className="activeContent margin">
              <b>Zone:</b> {data?.data?.zoneName}
            </Col>
            <Col span={24} className="activeContent margin">
              <b>Device Name:</b> {data?.data?.deviceName}
            </Col>
          </Row>
        </Col>
        <Col md={11} lg={9} xl={9}>
          <Row gutter={10}>
            {data?.isActiveProcess === true ? processButton() : processNormalButton()}
          </Row>
        </Col>
      </Row>
      <hr className="hr" />
      <Row className="deviceImgCard">
        <Col span={24} className="deviceScanTag">
          <Row>
            <Col span={24} className={getDeviceStatusByMac?.data?.result[0]?.status === 'connected' || getDeviceStatusByMac?.data?.result[0]?.status === 'running' ? "deviceCardDetail colorOnline" : "deviceCardDetail colorOffnline"}>{getDeviceStatusByMac?.data?.result[0]?.status === 'connected' || getDeviceStatusByMac?.data?.result[0]?.status === 'running' ? 'DEVICE ONLINE' : 'DEVICE OFFLINE'}</Col>
            <Col span={24} className="deviceCardDetail">
              {getDeviceStatusByMac?.data?.result[0]?.status === 'connected' || getDeviceStatusByMac?.data?.result[0]?.status === 'running' ? <UIImage src={officeDevice} alt="officedevice" height="60%" width="60%" /> : <UIImage src={offlineDevice} alt="offlineDevice" height="60px" width="60px" />}
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <section className="stepswrapper">
            <Row gutter={[20, 20]} >
              {/* <Col  span={24}>
                <Row>
                {steps?.map((step: any, id: any) => {
                return <Col span={6} className="upperRow"><span className="spanTag slice" key={id}>{step?.name}</span></Col>
              })}
                </Row>
                </Col> */}

                {getUpperRowStepper()}
              <Col span={24}>
                <Row gutter={[20, 20]} className={getClassNameForSteps()}>
                  {/* <span className="loopborder1" style={{ left: 45, width: steps?.length === 1 ? `calc(100% - 45px)` : `calc(100% - 90px)` }}></span>
                  {steps?.length === 1 ?
                    <span className="loopborder" style={{ right: 0, width: `calc(100% - ${(distance + 1) * 45}px)` }}></span>
                    :
                    <span className="loopborder" style={{ right: 45, width: `calc(100% - 2*${(distance + 1) * 45}px)` }}></span>
                  }
                  <span className="looparrow" style={{ right: `calc(100% - 2*${(distance + 1) * 45}px + 39px)` }}></span> */}
                  {/* <span className="stepline" style={{ width: steplinewidth }}></span> */}
                  {getStepArrowMarker()}
                  {imageForProcessAction()}
                </Row>
              </Col>
            </Row>
          </section>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="timeStatus">
          <img src={syncClock} className="syncclock" alt="clock" />
          <span className="currentTime">{CurrentDate}</span>
          <span className="lastSync">last sync time: {moment(syncedData?.lastSyncTime).format("hh:mm:ss A MM/DD/YYYY")}</span>
        </Col>
      </Row>
      <UIConfirmModal
        key="selectSiteDevice"
        className="siteDeviceform"
        visible={exitModal}
        cancelSiteButton={onExitModalClose}
        cancelButton={ModalButton.CANCEL}
        primaryText={ModalPrimaryText.PROCESS_EXIT}
        confirmButton={ModalButton.CONFIRM}
        type={ModalType.WARN}
        confirmCallback={onStopClick}
      />
    </>
  );  
};

