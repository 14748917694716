import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import storage from 'redux-persist/lib/storage';
import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import { apiSlice } from './services/apiSlice'
import authReducer from './features/auth/authSlice'
import tenantReducer from './features/tenant/tenantSlice'
import appReducer, { setSessionExist } from "./features/app/appSlice"
import { processService } from './services/processSlice';
import { authApiSlice } from './services/authApiSlice';
import { siteService } from './services/siteSlice';
import filterReducer from "./features/filter/filterSlice";
import { reduxencryptStorage } from '../utils/storage';


const persistConfig = {
  key: 'root',
  storage: reduxencryptStorage,
  blacklist: ['api'],
}

export const rootReducers = combineReducers({
  // Add the generated reducer as a specific top-level slice
  [apiSlice.reducerPath]: apiSlice.reducer,
  [authApiSlice.reducerPath]: authApiSlice.reducer,
  [processService.reducerPath]: processService.reducer,
  [siteService.reducerPath]: siteService.reducer,
  auth: authReducer,
  tenant: tenantReducer,
  app: appReducer,
  filter: filterReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducers)

const sessionExpirationMiddleware = (storeAPI:any) => (next:any) => (action:any) => {
    if (action.payload?.status === 401) {
      // If the error status is 401 (Unauthorized), dispatch sessionExpired action
      storeAPI.dispatch(setSessionExist(false));
    }
  return next(action);
};

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  }).concat([
    apiSlice.middleware,
    authApiSlice.middleware,
    processService.middleware,
    siteService.middleware,
    sessionExpirationMiddleware
  ]),
  devTools: true
});

setupListeners(store.dispatch);