import { Modal, Row, Col } from "antd"
import { WarningFilled } from '@ant-design/icons';
import './style.css'
import { Page } from "../../../routes/config";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentAppState, setSideBarState, setStopButton, setSyncedData, setTableData } from "../../../redux/features/app/appSlice";

export const StopEventAlert = (props: any) => {

    const {openAlert, setOpenAlert, alertDetails, processData} = props
    const dispatch = useDispatch()

//  const {stopbutton, showSidebar, syncedData, tableData} = useSelector(selectCurrentAppState)


    const handleOk = () => {
        console.log("okay")
    };
 
    const navigate = useNavigate();
    const handleCancel = () => {    
        if(window.location.pathname === Page.ACTIVITY_LOGS && processData?.data?.processId === alertDetails?.processId){
            navigate(Page.ACTIVE_PROCESS)
            setOpenAlert(false);
            dispatch(setTableData([]))
            dispatch(setSyncedData({}))
            dispatch(setStopButton("start"))
        }else{
            setOpenAlert(false);
            dispatch(setTableData([]))
            dispatch(setSyncedData({}))
            dispatch(setStopButton("start"))
        }        
    };

    return (
        <Modal visible={openAlert} footer={null}
            title={null}
            onOk={handleOk}
            onCancel={handleCancel}
            key="alertmodal"
            className="alertStop">

            <Row>
                <Col span={24} className="redCrossImg">
                    <WarningFilled style={{fontSize:"500%"}} className="warning" />
                </Col>
                <Col span={24} className="alertText">Process has been forcefully stopped by {alertDetails?.stopByName}</Col>
            </Row>
        </Modal>
    )
}