/* eslint react-hooks/exhaustive-deps: 0 */

import { LeftOutlined } from "@ant-design/icons";
import { Card, Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { syncOff, onlineLog, offlineLog, syncOn } from "../../../assets/images";
import { PageTitle } from "../../../config/enum";
import { setSideBarState, setSyncedData, setTableData } from "../../../redux/features/app/appSlice";
import { authStateSelector } from "../../../redux/features/auth/authSlice";
import { useProcessTagDataQuery } from "../../../redux/services/tagSlice";
import { socket } from "../../../redux/services/webSocket";
import { UIImage } from "../../common/image";
import { ActivityLogsTable } from "./activitylogsTable";
import { DeviceDetail } from "./deviceDetail";
import './style.css'
import { ProcessName } from "../../../config/constants";

export const ActivityLogs = () => {
  const dispatch = useDispatch()
  const {state} = useLocation()
  const [disconnect, setDisconnect] = useState("Online")
  const {syncedData} = useSelector((state:any)=>state.app)
  const {user} = useSelector(authStateSelector)

  const data = state ? state : undefined
  const tagsProcessData = useProcessTagDataQuery({ processId: data?.data?.processId, deviceId: data?.data?.deviceId, userId:user?.userId })

  useEffect(()=>{
    dispatch(setSideBarState(false))
    return(()=>{dispatch(setSideBarState(true))
    dispatch(setTableData([]))
    dispatch(setSyncedData({}))})
  },[])
 
  socket.on("disconnect",(reason:any)=>{
    setDisconnect("Offline")
  })

  const navigate = useNavigate()
  
  socket.on("connect",()=>{
    setDisconnect("Online")
  })

  socket.on("last-sync",(lastSyncdata:any)=>{
    console.log("sync data",data)
    if((data?.data?.processId == lastSyncdata?.data?.processId) && (data?.data?.deviceId == lastSyncdata?.data?.deviceId )){
      dispatch(setSyncedData(lastSyncdata?.data))
    }
  })

 useEffect(()=>{
  if(tagsProcessData?.data){
    dispatch(setSyncedData({...tagsProcessData?.data?.result}))
  }
 },[tagsProcessData?.data, tagsProcessData?.isSuccess])

useEffect(()=>{
  return()=>{
    dispatch(setTableData([]))
  }
},[])
 const getCaptureContent = () => {
  switch (data?.data?.processName) {
    case ProcessName.CHECK_STOCK:
      return (<>
        <Col span={24} className="capture">Scan cycles since start : {syncedData?.totalCycles ? syncedData?.totalCycles : 0 }</Col>
        <Col span={24} className="capture">Unsuccessful scans<span className="marginLeft"> : {syncedData?.totalFailed ? syncedData?.totalFailed : 0}</span>
        </Col>
        </>
      )
    default:
      return (<>
        <Col span={24} className="capture">Items captured since login : {syncedData?.totalCaptured ? syncedData?.totalCaptured : 0 }</Col>
        <Col span={24} className="capture">Items synced to portal<span className="marginLeft"> : {syncedData?.totalSynced ? syncedData?.totalSynced : 0}</span>
        </Col>
        </>
      )
  }
 }
console.log(">>>>>>>>>>>>Location data", data)
  return (
    <>
      <Row gutter={24} className="cardFirst">
        <Col md={11} lg={13} xl={13}>
          {/* <section className="listing-section"> */}
          <Card className="uicard table activityLogsTable">
            <Row className="justifyText">
            <Col span={24} style={{ marginBottom: "5px" }}>
                  <div onClick={()=>navigate(-1)} className="backButton">
                    <LeftOutlined className="bckIcn left-back-button" />
                    <b className="top-back-text">BACK</b>   
                  </div>
              </Col>
              <Col sm={24} md={24} lg={12} xl={14} className="title flex">
                {PageTitle.ACTIVITY_LOGS}
              </Col>
              <Col sm={24} md={24} lg={12} xl={10} className="columnContent">
                <Row className="itemCapture">
                  {getCaptureContent()}
                </Row>
              </Col>
            </Row>
            <hr className="hr" />

            <ActivityLogsTable logsTableData={data} tagsReadData={tagsProcessData} processData={data?.data}/>
          </Card>
          {/* </section> */}
        </Col>
        <Col md={13} lg={11} xl={11}>
          {/* <section className="listing-section"> */}
          <Card className="uicard activityLogsTable">
            <DeviceDetail data={data} syncedData={syncedData} />
          </Card>
          {/* </section> */}
        </Col>
      </Row>
      {/* </div> */}

      <Row className="cardBottom">
        <Card className="uicard card-container " key={disconnect}>
          <Row>
            <Col span={24} className="lastLogsCard">
              <UIImage src={(syncedData?.totalSynced || syncedData?.totalCycles) ? syncOn : syncOff} width="60px" height="60px" />
              <span className={(syncedData?.totalSynced || syncedData?.totalCycles) ? "online synced" : "off-sync synced"}>
                <b>{(syncedData?.totalSynced || syncedData?.totalCycles) ? "Synced" : "Not Synced"}</b>
              </span>
              <UIImage src={disconnect === "Online" ? onlineLog : offlineLog} width="60px" height="60px" />
              <span className={disconnect === "Online" ? "online synced" : "offline synced"}>
                <b>{disconnect}</b>
              </span>
            </Col>
          </Row>
        </Card>
      </Row>
    </>
  );
};
