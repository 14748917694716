import { createSlice } from '@reduxjs/toolkit'

const initialState = { showSideBar:  true, stopbutton: "", tableData: [], syncedData: {}, currentStep:0, sessionExist: null }

const AppSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setSideBarState(state, action) {
      state.showSideBar = action.payload
      return state
    },

    setStopButton(state, action){
      state.stopbutton = action.payload
      return state
    },

    setTableData(state, action){
      state.tableData = action.payload
      return state
    },

    setSyncedData(state, action){
      state.syncedData = action.payload
      return state
    },

    setCurrentStep(state, action){
      state.currentStep = action.payload;
      return state
    },

    setSessionExist(state, action){
      state.sessionExist = action.payload
      return state
    }
  },
  extraReducers: (builder) => { }
})

export const { setSideBarState, setStopButton, setTableData, setSyncedData, setCurrentStep, setSessionExist } = AppSlice.actions
export default AppSlice.reducer

export const selectCurrentAppState = (state: any) => state.app