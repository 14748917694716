/* eslint react-hooks/exhaustive-deps: 0 */

import {
  Alert,
  Card,
  Form,
  message,
  TablePaginationConfig,
} from "antd";
import type { FilterValue, SorterResult } from "antd/es/table/interface";
import { UITable } from "../../common/table";
import { useEffect, useState } from "react";
import "./style.css";
import { page } from "../../../config/constants";
import { ReactComponent as PlayIcon } from '../../../assets/images/playBtn.svg'
import { SiteDeviceForm } from "./siteDeviceForm";
import { UIConfirmModal } from "../../common/confirmModal";
import { ModalButton } from "../../../config/enum";
import { useNavigate } from "react-router-dom";
import { Page } from "../../../routes/config";
import { useGetAllExecuteProcessQuery } from "../../../redux/services/processSlice";
import { useDispatch, useSelector } from "react-redux";
import { authStateSelector } from "../../../redux/features/auth/authSlice";
import { useCancelExecuteProcessMutation, useCreateProcessStateMutation, useGetAllDeviceBySiteQuery, useGetAllSiteQuery, useProcessExecuteMutation } from "../../../redux/services/siteSlice";
import moment from "moment";
import UITooltip from "../../common/tooltip";
import search from "antd/lib/transfer/search";
import { setStopButton, setSyncedData, setTableData } from "../../../redux/features/app/appSlice";
import {  getFilterInfo, setFilterState } from "../../../redux/features/filter/filterSlice";

export const ExecuteProcess = (props: any) => {
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [deviceSelectModal, setDeviceSelectModal] = useState(false);
  const [total, setTotal] = useState(0);
  const filterState = useSelector(getFilterInfo);
  const currentURL = window.location.href;
  const [sort, setSort] = useState<any>({
    ...((currentURL == filterState?.url && filterState?.sortBy) && {sortBy: filterState?.sortBy}),
    ...((currentURL == filterState?.url && filterState?.sortOrder) && {sortOrder: filterState?.sortOrder}),
  });
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: filterState?.page &&  currentURL == filterState?.url ?  filterState?.page : page?.current,
    pageSize: filterState.limit && currentURL == filterState?.url ? filterState.limit : page?.pageSize,
    showSizeChanger: true,
  })
  const { tenantId, user } = useSelector(authStateSelector)
  const [loading, setLoading] = useState(false);
  const [tableData, setExecuteTableData] = useState<any>([]);
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState<any>({ siteId: "", deviceId: "", zoneName: "" })
  let queryParams = {
    page:  pagination.current,
    limit: pagination.pageSize,
    type: "ExecuteProcess",
    tenantId: tenantId,
    ...sort
  }
  const [deviceInfo, setDeviceInfo] = useState({})
  const getAllSiteByUserQuery = useGetAllSiteQuery({ userId: user?.userId, roleName: user?.roleName }, { refetchOnMountOrArgChange: true })
  const getExecuteProcess = useGetAllExecuteProcessQuery({ queryParams, roleId: user?.roleId }, { refetchOnMountOrArgChange: true })
  useEffect(() => {
    if (getExecuteProcess?.isSuccess) {
      getExecuteProcess?.refetch()
      setExecuteTableData(getExecuteProcess?.data?.result)
      setTotal(getExecuteProcess?.data?.result?.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getExecuteProcess?.isSuccess])

  useEffect(() => {
    setExecuteTableData(getExecuteProcess?.data?.result)
  }, [getExecuteProcess?.fulfilledTimeStamp])


  useEffect(() => {
    if (getExecuteProcess?.isError) {
      message?.error({ content: "No process to Execute", key: "notificationKey", className: "errorMessage", duration: 1 })
    }
  }, [getExecuteProcess?.isError])

  const navigate = useNavigate();

  useEffect(() => {
    getExecuteProcess?.refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getAllSiteOptions = () => {
    if (getAllSiteByUserQuery?.data?.result) {
      var temp: any[] = [];
      temp = getAllSiteByUserQuery?.data?.result?.map((site: any) => {
        return {
          text: site?.name,
          value: site?.siteId,
        };
      });
      return temp;
    } else if (getAllSiteByUserQuery?.data) {
      var temp2: any[] = [];
      temp2 = getAllSiteByUserQuery?.data?.map((site: any) => {
        return {
          text: site?.name,
          value: site?.siteId,
        };
      });
      return temp2;
    }
  };

  useEffect(()=>{
    dispatch(setFilterState({
      page: queryParams?.page,
      limit : queryParams?.limit,
      sortBy: queryParams?.sortBy,
      sortOrder: queryParams?.sortOrder
    }));
},[
  queryParams?.page,
  queryParams?.limit,
  queryParams?.sortBy,
  queryParams?.sortOrder
]);

  const getAllDeviceBySiteQuery = useGetAllDeviceBySiteQuery({ siteId: formValues?.siteId }, { refetchOnMountOrArgChange: true })
  const [x, setX] = useState<any>({})
  useEffect(() => {
    const v = getAllDeviceBySiteQuery?.data?.result?.find((x: any) => x?.deviceId === formValues?.deviceId)
    if (v) {
      setX(v)
    }
  }, [formValues?.deviceId])

  const [zone, setzone] = useState<any>()

  useEffect(() => {
    if (x?.deviceSiteZoneProcess?.length) {
      setzone(x?.deviceSiteZoneProcess[0])
    }
  }, [x])

  const getAllDeviceOptions = () => {
    var temp: any[] = []
    temp = getAllDeviceBySiteQuery?.data?.result?.map((device: any) => {
      return {
        text: device?.name,
        value: device?.deviceId,
      }
    })
    return temp
  }

  const [executeProcess, setExecuteProcess] = useProcessExecuteMutation()
  const [cancelProcess, setCancelprocess] = useCancelExecuteProcessMutation()
  const [stateCreation, stateCreationInfo] = useCreateProcessStateMutation()

  const onRowClick = (row: any) => {
    setSelectedRow(row);
    executeProcess({ processId: row?.processId, userId: user?.userId })
    console.log(row, "processexecute")
  };

  const onPlayClick = (row: any) => {
    setSelectedRow(row)
    executeProcess({ processId: row?.processId, userId: user?.userId })
  };

  useEffect(() => {
    if (setExecuteProcess?.isSuccess) {
      setDeviceSelectModal(true);
    } else if (setExecuteProcess?.isError) {
      message.error({ content: setExecuteProcess?.error?.data?.error?.message, key: "notificationKey", className: "errorMessage", duration: 2 })
    }
  }, [setExecuteProcess?.isSuccess, setExecuteProcess?.isError])

  const onDeviceModalClose = () => {
    cancelProcess({ processId: selectedRow?.processId, userId: user?.userId, type: 'cancelBeforeDeviceSelection' })
    form.resetFields();
    setFormValues({
      siteId: "",
      zoneName: "",
      deviceId: "",
    })
    form.setFieldsValue({
      siteId: "",
      zoneName: "",
      deviceId: "",
    });
    setzone({})
  };

  useEffect(() => {
    if (setCancelprocess?.isSuccess) {
      setDeviceSelectModal(false);
      form.resetFields();
    } else if (setCancelprocess?.isError) {
      setDeviceSelectModal(false);
    }
  }, [setCancelprocess?.isSuccess, setCancelprocess?.isError])


  const handleApply = () => {
    dispatch(setStopButton("start"))
    setDeviceInfo({ ...formValues, ...selectedRow, ...x, processName: selectedRow?.name })
    stateCreation({
      ...formValues,
      zoneId: x.zoneId,
      userId: user.userId,
      userState: "login",
      tenantId: tenantId,
      processId: selectedRow?.processId,
      roleId: user?.roleId
    })
  };


  useEffect(() => {
    if (stateCreationInfo?.isSuccess) {
      navigate(Page.ACTIVITY_LOGS, { state: { data: deviceInfo, check: stateCreationInfo?.data } })
    }
  }, [stateCreationInfo?.isSuccess, stateCreationInfo?.isError])
  const [showAlertBar, setShowAlert] = useState(false)

  useEffect(() => {
    if (stateCreationInfo?.isError) {
      setShowAlert(true)
    }
    return () => {
      setTimeout(() => setShowAlert(false), 3000)
    }
  }, [stateCreationInfo?.isError])

  const showAlert = () => {
    if (stateCreationInfo?.isError) {
      return (
        showAlertBar && <Alert message={stateCreationInfo?.error?.data?.error?.message} type="error" showIcon className="errorMessage" />
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    setExecuteTableData(getExecuteProcess?.data?.result)
  }, [])

  const columns = [
    {
      key: 'tenantName',
      title: "Tenant",
      dataIndex: "tenantName",
      className: "executeProcessTable",
      sorter: true,
      ellipsis: true,
      width: "11%",
      render: (field: any, row: any) => {
        return (
          <div
            className="d-flex align-items-center"
            onClick={() => onRowClick(row)}
          >
            <p className="pl-10 tableData">{field}</p>
          </div>
        );
      },
    },
    {
      title: "Process Name",
      dataIndex: "name",
      sorter: true,
      className: "executeProcessTable",
      key: "name",
      ellipsis: true,
      width: "15%",
      render: (field: any, row: any) => {
        return (
          <div
            className="d-flex align-items-center"
            onClick={() => onRowClick(row)}
          >
            <p className="pl-10 tableData">{field}</p>
          </div>
        );
      },
    },

    {
      title: "DESCRIPTION",
      dataIndex: "description",
      key: "description",
      className: "executeProcessTable",
      width: "15%",
      sorter: true,
      render: (field: any, row: any) => {
        return (
          <div
            className="d-flex align-items-center"
            onClick={() => onPlayClick(row)}
          >
            <UITooltip title={row?.description} placement="top">
              <p className="pl-10 slice tableData">{field}</p>
            </UITooltip>
          </div>
        );
      },
    },
    {
      title: "LAST EXECUTED TIME",
      dataIndex: "lastExecutedTime",
      key: "lastExecutedTime",
      width: "20%",
      className: "executeProcessTable",
      sorter: true,
      ellipsis: true,
      render: (field: any, row: any) => {
        return (
          <div onClick={() => onRowClick(row)}>
            <span className="tableData">{row?.lastExecutedTime ? moment(row?.lastExecutedTime).format("DD MMM YYYY hh:mm A") : ""}</span>
          </div>
        );
      },
    },
    {
      dataIndex: "login",
      width: "4%",
      key: "login",
      fixed: "right",
      render: (login: any, row: any) => {
        return (
          <div className="clickable" onClick={() => onPlayClick(row)}>
            <PlayIcon
              className="playbtn"
            />
          </div>
        );
      },
    },
  ];

  interface DataType {
    name: {
      first: string;
      last: string;
    };
    description: string;
    lastUpdated: string;
    createdAt: string;
  }
  interface Params {
    pagination?: TablePaginationConfig;
    sorter?: SorterResult<any> | SorterResult<any>[];
    total?: number;
    sortField?: string;
    sortOrder?: string;
  }
  useEffect(() => {
    setPagination({
      ...pagination,
      locale: { items_per_page: "" },
      total,
    });
  }, [total]);

  const fetchData = (params: Params = {}) => {
    setLoading(true);
    getExecuteProcess?.refetch();
    setSort({
      sortBy: params.sortField,
      sortOrder:
        params.sortOrder === "ascend"
          ? "1"
          : params.sortOrder === "descend"
            ? "-1"
            : undefined,
    });
    setPagination({
      ...params.pagination,
      locale: { items_per_page: "" },
      total,
    });
  };
  const handleTableChange = (
    newPagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<DataType>
  ) => {
    fetchData({
      sortField: sorter.field as string,
      sortOrder: sorter.order as string,
      pagination: newPagination,
      ...filters,
    });
    setLoading(false);
  };

  const dispatch = useDispatch()

  return (
    <>
      <section className="listing-section">
        <Card className="uicard table uiinner-card">
          <div className="card-body">
            <UITable
              columns={columns}
              data={tableData}
              pagination={pagination}
              handleTableChange={handleTableChange}
              scroll={{ x: 1000, y: 500 }}
              rowSelection={onRowClick}
            />
          </div>
        </Card>
      </section>
      <UIConfirmModal
        key="selectSiteDevice"
        className="siteDeviceform"
        visible={deviceSelectModal}
        cancelSiteButton={onDeviceModalClose}
        cancelButton={ModalButton.CANCEL}
        confirmButton={ModalButton.APPLY}
        type={false}
        btnType="submit"
        form="selectSiteDevice"
      >
        <SiteDeviceForm
          id="selectSiteDevice"
          form={form}
          showAlert={showAlert()}
          onModalSubmit={handleApply}
          siteOptions={getAllSiteOptions()}
          deviceOptions={getAllDeviceOptions()}
          zoneData={zone}
          setZoneData={setzone}
          formValues={formValues} setFormValues={setFormValues} />
      </UIConfirmModal>
    </>
  );

};
function stopProcess(arg0: { userId: any; stopBy: any; processName: any; activity_logs: boolean; processId: any; type: string; deviceId: any; }) {
  throw new Error("Function not implemented.");
}

