import { WebsocketKey } from "../../../config/enum";
import { UITable } from "../../common/table"
import { socket } from "../../../redux/services/webSocket";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectCurrentAppState, setStopButton, setTableData } from "../../../redux/features/app/appSlice";
import { useEffect } from "react";
import { authStateSelector } from "../../../redux/features/auth/authSlice";
import { ProcessName } from "../../../config/constants";
import moment from "moment";

export const ActivityLogsTable = (props: any) => {

  const { logsTableData, tagsReadData, processData } = props
  const dispatch = useDispatch()
  const { tableData } = useSelector((state: any) => state.app)
  const { stopbutton } = useSelector(selectCurrentAppState)


const dataOfTags = (data:any[]) => {
  let tagsData:any[] = [];
  data?.forEach((el: any) => {
    let x = tagsData?.findIndex((y: any) => y?.upc == el?.upc)
    if(x != -1){
      tagsData[x] = {...tagsData[x],qty:tagsData[x]?.qty + 1}
    }else{
      tagsData.push({...el, qty:1})
    }
  })
  return tagsData;
}

  useEffect(() => {
    if (tagsReadData?.data?.result) {
      if(processData.processName == ProcessName.CHECK_STOCK){
      dispatch(setTableData(tagsReadData?.data?.result?.data?.data))
    } else {
      dispatch(setTableData(dataOfTags(tagsReadData?.data?.result?.data?.data)))
    }
   }
    },[tagsReadData?.data, tagsReadData?.isSuccess])
 

  socket.on(WebsocketKey.ACTIVITY_LOGS_TABLE, (data: any) => {
    console.log(data , "data from activity log");
    if(processData.processName == ProcessName.CHECK_STOCK){
      if(data?.state !== "TrackNTrace"){
        console.log(data, "data .......", logsTableData)
        if((logsTableData?.data?.processId == data?.data?.processId )&& (logsTableData?.data?.deviceId == data?.data?.deviceId)){
          dispatch(setTableData([{ ...data?.data},...tableData]))
        }
      }
    }
    else {
      if (data?.data?.data) {
      if (logsTableData?.data?.processId === data?.data?.processId && logsTableData?.data?.deviceId === data?.data?.deviceId) {
        dispatch(setTableData([...tableData, { ...data?.data?.data?.data, qty: 1 }]))
        const x = tableData?.find((y: any) => y?.upc === data?.data?.data?.data?.upc)        
        if (x) {
          dispatch(setTableData(tableData?.map((y: any) => {
            if (x.upc === y.upc) {
              return { ...y, qty: y.qty + 1 }
            } else {
              return y
            }
          })))
        }
      }
    } else if (!data?.data?.data) {
      return null
    }
  }
  })

  useEffect(()=>{
    if(stopbutton === ""){
      dispatch(setTableData([]))
    }else(
      dispatch(setTableData([...tableData]))
    )
  },[])
 

  const getTableColumns = (processName: any) => {
    let renderColumns :any[] = []

    switch(processName){
      case ProcessName.CHECK_STOCK:
        renderColumns =[
          {
            title: "SCAN TIMESTAMP",
            dataIndex: "scanTime",
            className: "noPadCell",
            width: "20%",
            render: (field: any, row: any) => {
              return (
                <div
                  className="d-flex align-items-center"
                >
                  <div className="pl-10 tableData">{moment(field ? field : row?.createdAt).format("DD-MMM-YYYY hh:mm:ss A")}</div>
                </div>
              );
            },
          },
          {
            title: "QTY EXPECTED",
            dataIndex: "expectedTags",
            className: "centerTable",
            width: "15%",
            render: (tags: any, row: any) => {
              return (
                <div>
                  <span className="tableData">{tags?.length}</span>
                </div>
              );
            },
          },
          {
            title: "QTY RECEIVED",
            dataIndex: "receivedTags",
            className: "centerTable",
            width: "15%",
            render: (tags: any, row: any) => {
              return (
                <div>{tags?.length}</div>
              );
            },
          },
          {
            title: "SCAN STATUS",
            dataIndex: "status",
            className: "centerTable",
            width: "15%",

            render: (status: any, row: any) => {
              return (
                <div>{status? "Passed": "Failed"}</div>
              );
            },
          },
          {
            title: "NOTIFICATION STATUS",
            dataIndex: "notificationStatus",
            width: "20%",
            className: "centerTable",
            render: (notified: any, row: any) => {
              return (
                <div>{notified? "Sent": "Not Sent"}</div>
              );
            },
          },
        ];
      
        break;
      default:
        renderColumns = [
          {
            title: "UPC",
            dataIndex: "upc",
            render: (field: any, row: any) => {
              return (
                <div
                  className="d-flex align-items-center"
                >
                  <div className="pl-10 tableData">{field}</div>
                </div>
              );
            },
          },
          {
            title: "PRODUCT NAME",
            dataIndex: "description",
            render: (_: any, row: any) => {
              return (
                <div>
                  <span className="tableData">{row?.description}</span>
                </div>
              );
            },
          },
          {
            title: "QTY",
            dataIndex: "qty",
            render: (createdAt: any, row: any) => {
              return (
                <div>{row?.qty}</div>
              );
            },
          },
        ];
    }
    return renderColumns
  }
  const columns = [
    {
      title: "UPC",
      dataIndex: "upc",
      render: (field: any, row: any) => {
        return (
          <div
            className="d-flex align-items-center"
          >
            <div className="pl-10 tableData">{field}</div>
          </div>
        );
      },
    },
    {
      title: "PRODUCT NAME",
      dataIndex: "description",
      render: (_: any, row: any) => {
        return (
          <div>
            <span className="tableData">{row?.description}</span>
          </div>
        );
      },
    },
    {
      title: "QTY",
      dataIndex: "qty",
      render: (createdAt: any, row: any) => {
        return (
          <div>{row?.qty}</div>
        );
      },
    },
  ];

  return (
    <>
      <div className="card-body activityTable1">
        <UITable
          columns={getTableColumns(processData?.processName)}
          data={tableData}
          pagination={false}
          scroll={{ x: 800, y: 500 }}
          className="activitytable"
        />
      </div>
    </>
  )
}